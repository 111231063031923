<template>
  <div>
    <VTitle :title="$t('app.lessons', 2)" class="section-title" />

    <VList hide-header :headers="headers" :rows="data" :is-loading="isLoading">
      <!-- eslint-disable-next-line -->
      <template #item.image="{ item }">
        <VImage
          :color="item?.lesson?.colour"
          :name="getText(item?.lesson?.texts, 'name')"
        />
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.lesson="{ item }">
        {{ getText(item?.lesson?.texts, "name") }}

        <div class="version mt-0.5">
          {{ $t("app.versions") }} {{ item.lesson?.version }}
        </div>
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.started_on="{ item }">
        {{ formatDate({ date: item?.started_on }) }}
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.completed_on="{ item }">
        {{ formatDate({ date: item?.completed_on }) }}
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.training="{ item }">
        {{ getText(item?.registration?.training?.texts, "name") }}

        <div class="version mt-0.5">
          {{ $t("app.versions") }} {{ item.registration?.training?.version }}
        </div>
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.status="{ item }">
        <div class="flex justify-center">
          <VChip
            :text="$t(`app.${item.status}`)"
            :class="getStatusColor(item.status)"
          />
        </div>
      </template>
    </VList>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useRequest from "@/composables/useRequest";
import useTexts from "@/composables/useTexts";
import useColor from "@/composables/useColor";
import useDisplay from "@/composables/useDisplay";
// Components
import VTitle from "@/components/VTitle";
import VList from "@/components/tables/VList";
import VImage from "@/components/VImage";
import VChip from "@/components/VChip";

export default {
  components: {
    VTitle,
    VList,
    VImage,
    VChip
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();

    // Composables
    const { request } = useRequest();
    const { getText } = useTexts();
    const { getStatusColor } = useColor();
    const { formatDate } = useDisplay();

    // Constants
    const headers = [
      {
        value: "image",
        class: "w-10"
      },
      {
        text: t("app.lessons"),
        value: "lesson"
      },
      {
        text: t("app.trainings"),
        value: "training"
      },
      {
        text: t("app.started_on"),
        value: "started_on"
      },
      {
        text: t("app.completed_on"),
        value: "completed_on"
      },
      {
        text: t("app.status"),
        value: "status"
      }
    ];

    // Data
    const data = ref([]);
    const isLoading = ref(false);

    // Computed
    const documentTitle = computed(() => {
      // eslint-disable-next-line
      const title = `${t("app.lessons", 2)} - ${t("app.learners", 2)} - ${t("app.teaching")}`;

      const { firstname, lastname } = props.resource?.firstname ?? {};
      if (firstname && lastname) {
        return `${firstname} ${lastname} - ${title}`;
      }

      return title;
    });

    // Methods
    const getData = async () => {
      isLoading.value = true;

      const response = await request({
        endpoint: "teaching.learners.lessons",
        pathParams: {
          id: props.id
        },
        showToaster: false
      });

      data.value = response?.payload?.data ?? [];
      isLoading.value = false;
    };

    // Lifecycle Hooks
    onMounted(async () => {
      await getData();
    });

    return {
      documentTitle,
      headers,
      data,
      isLoading,
      // useDisplay
      formatDate,
      // useTexts
      getText,
      // useColor
      getStatusColor
    };
  }
};
</script>
